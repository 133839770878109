import React from "react"
import { graphql } from "gatsby"
import Preowned from "../components/routes/pre_owned"
import Header from "../components/header"
import Seo from "../components/seo"
import Footer from "../components/footer"
import { menuColor } from "./menu_color"
import { Flex } from "theme-ui"
import { LabelsProvider } from "../utils/labels_context"

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    const globalNavi = Object.assign({}, props.pageContext.globalNavi)
    const footer = Object.assign({}, props.pageContext.footer)
    const labelTranslations = Object.assign(
      {},
      props.pageContext.labelTranslations,
    )
    story.content = JSON.parse(story.content)
    globalNavi.content = JSON.parse(globalNavi.content)
    footer.content = JSON.parse(footer.content)
    labelTranslations.content = JSON.parse(labelTranslations.content)

    return { story, globalNavi, footer, labelTranslations }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let content = this.state.story.content
    let globalNavi = this.state.globalNavi.content
    let footer = this.state.footer.content
    let langSwitch = this.state.story.translated_slugs
    content.lang =
      content.seo.lang =
      globalNavi.lang =
      langSwitch.currentLang =
        this.state.story.lang
    content.loop = JSON.parse(this.props.data.loop.content)
    content.tech_note = JSON.parse(this.props.data.tech_note.content)
    content.contact = [JSON.parse(this.props.data.contact.content)]

    return (
      <Flex sx={{ minHeight: "100vh", flexDirection: "column" }}>
        <LabelsProvider
          value={{
            lang: this.state.story.lang,
            labels: this.state.labelTranslations.content,
          }}
        >
          <Header
            blok={globalNavi}
            langs={langSwitch}
            menuColor={menuColor(content)}
          ></Header>
          <Preowned key={content._uid} blok={content} />
          <Footer blok={footer} langs={langSwitch} />
        </LabelsProvider>
      </Flex>
    )
  }
}

export default StoryblokEntry

export const Head = props => (
  <Seo meta={props.pageContext.story} lang={props.pageContext.lang} />
)

export const pageQuery = graphql`
  query ($lang: String!) {
    loop: storyblokEntry(
      slug: { eq: "pre-owned-related-content" }
      lang: { eq: $lang }
    ) {
      id
      name
      lang
      translated_slugs {
        lang
        path
      }
      content
      full_slug
      sort_by_date
      first_published_at
    }
    tech_note: storyblokEntry(
      slug: { eq: "pre-owned-tech-note" }
      lang: { eq: $lang }
    ) {
      id
      name
      lang
      content
      sort_by_date
      first_published_at
    }
    contact: storyblokEntry(
      slug: { eq: "pre-owned-contact-form" }
      lang: { eq: $lang }
    ) {
      id
      name
      lang
      content
    }
  }
`
